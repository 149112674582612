import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { Component } from 'react';

import { menuItems } from '../../Models/Data';

import Header from '../../components/Header/Header';
import Order from '../../components/Order/Order';
import './Home.scss';
import { MenuItemInfo } from '../../Models/Menu';

export interface EatingHabitState {
  eatingHabit: number;
  filteredMenuItems: MenuItemInfo[];
}

export default class Home extends Component<any, EatingHabitState>  {
  constructor(props: any) {
    super(props);
    this.state = { eatingHabit: 2, filteredMenuItems: menuItems };
  }

  public setEatingHabit = (e: any) => {
    const val = e.target.value as number;
    let filteredMenuItems = menuItems.filter(mi => mi.eatingHabit <= val);

    this.setState({ eatingHabit: val, filteredMenuItems: filteredMenuItems });
  }

  render() {
    return <IonPage>
      <IonHeader>
        <Header eatingHabit={this.state.eatingHabit} setEatingHabit={this.setEatingHabit} ></Header>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"> </IonTitle>
          </IonToolbar>
        </IonHeader>
        <Order menuItems={this.state.filteredMenuItems} />
      </IonContent>
    </IonPage>
  }
};