import React from "react";
import MenuItemList from "../MenuItemList/MenuItemList";
import { Category, MenuItemInfo, OrderInfo, SubCategory } from "../../Models/Menu";

import "./OrderList.scss";

const OrderList: React.FC<{ menuItems: MenuItemInfo[], addOrderInfo: (info: OrderInfo) => void }> = ({ children, menuItems, addOrderInfo }) => (
    <div id="overflow-scroll" className="pb-5">
        <MenuItemList menuItems={menuItems} addOrderInfo={addOrderInfo} />
    </div>
);

export default OrderList;