import { IonContent, IonHeader, IonPage } from '@ionic/react';
import React, { useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './MenuCard.scss';
import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalBody } from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MenuCard: React.FC = () => {

  const [fullMenuModal, setFullMenuModal] = useState(false);
  const [currentMenuImage, setCurrentMenuImage] = useState<string>();

  const toggleMenuPageView = (e: any) => {
    setCurrentMenuImage(e.target.src);
    setFullMenuModal(!fullMenuModal)
  }

  return (
    <IonPage>
      <IonHeader>
        <Header></Header>
      </IonHeader>
      <IonContent fullscreen >
        <div className="header-spacer bg-light"></div>
        <div id="carouselExampleIndicators" className="bg-light carousel carousel-dark carousel-fade menu-image-container p-4" data-bs-interval="false">
          <div className="carousel-indicators mt-4 pt-2">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active" id="page-1">
              <img src="/assets/images/menu-page-1.jpg" onClick={toggleMenuPageView} className="pt-4 d-block" alt="..." />
            </div>
            <div className="carousel-item" id="page-2">
              <img src="/assets/images/menu-page-2.jpg" onClick={toggleMenuPageView} className="pt-4 d-block " alt="..." />
            </div>
          </div>
          <button className="carousel-control-prev p-4 m-2" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next p-4 m-2" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <MDBModal staticBackdrop tabIndex='-1' show={fullMenuModal}>
          <MDBModalDialog centered size="fullscreen">
            <MDBModalContent>
              <MDBModalBody className="image-modal-body">
                <div className="image-container text-center">
                  <img src={currentMenuImage} className="p-4" onClick={toggleMenuPageView} alt="..." />
                  <button className="btn btn-sm btn-danger position-fixed" onClick={toggleMenuPageView}><FontAwesomeIcon icon="window-close" /></button>
                </div>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default MenuCard;


