import { faRulerHorizontal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { burgerAddOns, pizzaAddOns } from '../../Models/Data';
import { EatingHabitType, MenuItemInfo, OrderInfo, PizzaSize, ServeSize, SubCategory } from '../../Models/Menu';
import AddOns from '../AddOns/AddOns';

import './MenuItem.scss';

export interface MenuItemProps {
  menuItem: MenuItemInfo;
  addOrderInfo: (info: OrderInfo) => void
}

export interface MenuItemState {
  base: string,
  cheese: string,
  quantity: number;
  size: string,
  price: number,
  toppings: string[],
  sauce: string,
  pizzaAddOnsModal: any,
  cheeseSliceCount: number
}

export default class MenuItem extends Component<MenuItemProps, MenuItemState> {
  constructor(props: MenuItemProps) {
    super(props);

    this.state = this.intialState();
  }

  private intialState = (): MenuItemState => {
    const props = this.props;

    // var size = props.menuItem.subCategory == SubCategory.Pizza ? PizzaSize.Medium : Object.keys(props.menuItem.prices)[0];

    var size = this.getSize();
    return {
      base: "Normal",
      cheese: "Normal",
      quantity: 1,
      size: size,
      price: (props.menuItem.prices[PizzaSize.Medium] || props.menuItem.prices[ServeSize.Half] || props.menuItem.prices[ServeSize.Full]),
      toppings: [],
      sauce: "",
      pizzaAddOnsModal: null,
      cheeseSliceCount: 0
    };
  }

  public sizeChangedEvent = (e: any) => {
    const size = e.currentTarget.value;
    this.sizeChanged(size);
  }

  public quantityChangedEvent = (e: any) => {
    e.stopPropagation();
    let quantity = e.currentTarget.value;
    let state = JSON.parse(JSON.stringify(this.state)) as MenuItemState;
    state.quantity = quantity;
    this.calculatePrice(state);
    this.setState(state);
  }

  public baseChangedEvent = (e: any) => {
    let base = e.currentTarget.value;
    this.baseChanged(base);
  }

  public cheeseChangedEvent = (e: any) => {
    let cheese = e.currentTarget.value;
    this.cheeseChanged(cheese);
  }

  public sauceChangedEvent = (e: any) => {
    let sauce = e.currentTarget.value;
    this.sauceChanged(sauce);
  }

  public sizeChanged = (size: string) => {
    const price = this.props.menuItem.prices[size]
    let state = JSON.parse(JSON.stringify(this.state)) as MenuItemState;
    state.size = size;
    this.calculatePrice(state);
    this.setState(state);
  }

  public baseChanged = (base: string) => {
    base = this.state.base == base ? "" : base;
    let state = JSON.parse(JSON.stringify(this.state)) as MenuItemState;
    state.base = base;
    this.calculatePrice(state);
    this.setState(state);
  }

  public cheeseChanged = (cheese: string) => {
    cheese = this.state.cheese == cheese ? "" : cheese;
    let state = JSON.parse(JSON.stringify(this.state)) as MenuItemState;
    state.cheese = cheese;
    this.calculatePrice(state);
    this.setState(state);
  }

  public toppingsChanged = (topping: string) => {
    let toppings = this.state.toppings;
    if (toppings.includes(topping)) {
      toppings = toppings.filter(t => t != topping);
    }
    else {
      toppings.push(topping);
    }

    let state = JSON.parse(JSON.stringify(this.state)) as MenuItemState;
    state.toppings = toppings;
    this.calculatePrice(state);
    this.setState(state);
  }

  public sauceChanged = (sauce: string) => {
    sauce = this.state.sauce == sauce ? "" : sauce;
    let state = JSON.parse(JSON.stringify(this.state)) as MenuItemState;
    state.sauce = sauce;
    this.calculatePrice(state);
    this.setState(state);
  }


  public cheeseSliceChangedEvent = (e: any) => {
    let quantity = e.currentTarget.value;
    let state = JSON.parse(JSON.stringify(this.state)) as MenuItemState;
    state.cheeseSliceCount = quantity;
    this.calculatePrice(state);
    this.setState(state);
  }


  public addToCart = () => {
    let orderInfo = {
      menuItem: this.props.menuItem,
      base: this.state.base,
      cheese: this.state.cheese,
      cheeseSliceCount: this.state.cheeseSliceCount,
      price: this.state.price,
      quantity: this.state.quantity,
      sauce: this.state.sauce,
      size: this.state.size,
      toppings: this.state.toppings
    } as OrderInfo;

    this.props.addOrderInfo(orderInfo);

    this.setState(this.intialState());

    if (this.state.pizzaAddOnsModal) {
      this.toggleShow();
    }
  }

  public toggleShow = () => {
    const pizzaAddOnsModal = !this.state.pizzaAddOnsModal;
    this.setState({ pizzaAddOnsModal: pizzaAddOnsModal })
  };

  private calculatePrice = (state: MenuItemState): number => {
    let price = this.props.menuItem.prices[state.size];

    switch (this.props.menuItem.subCategory) {
      case SubCategory.Pizza:
        if (state.base) {
          price += (pizzaAddOns.Base as any)[state.base][state.size];
        }

        if (state.cheese) {
          price += (pizzaAddOns.Cheese as any)[state.cheese][state.size];
        }

        if (state.toppings) {
          state.toppings.forEach(t => {
            price += (pizzaAddOns.Topping as any)[t][state.size];
          })
        }

        if (state.sauce) {
          price += (pizzaAddOns.Sauce as any)[state.sauce][state.size];
        }
        break;

      case SubCategory.Burger:
        if (state.cheeseSliceCount) {
          price += state.cheeseSliceCount * burgerAddOns.CheeseSlice;
        }
        break;
    }

    state.price = price * state.quantity;
    return price;
  }

  private getEatingHabitIcon = () => {
    switch (this.props.menuItem.eatingHabit) {
      case EatingHabitType.Veg:
        return <span className={"position-absolute top-0 start-0 p-1 pt-0 pb-0 bg-light fw-bolder border border-3 text-success border-success"}>
          <FontAwesomeIcon icon="circle" />
        </span>
      case EatingHabitType.Egg:
        return <span className={"position-absolute top-0 start-0 p-1 pt-0 pb-0 bg-light fw-bolder fs-5 text-warning"}>
          <FontAwesomeIcon icon="egg" />
        </span>
      default:
        return <span className={"position-absolute top-0 start-0 p-1 pt-0 pb-0 bg-light fw-bolder border border-3 text-danger border-danger"}>
          <FontAwesomeIcon icon="circle" />
        </span>
    }
  }

  private getEatingHabitClass = () => {
    switch (this.props.menuItem.eatingHabit) {
      case EatingHabitType.Veg:
        return " text-success";
      case EatingHabitType.Egg:
        return " text-warning";
      default:
        return " text-danger";
    }
  }

  private getSize() {
    switch (this.props.menuItem.subCategory) {
      case SubCategory.Pizza:
        return PizzaSize.Medium;
      default:
        return ServeSize.Full;
    }
  }

  render() {
    let menuItem = this.props.menuItem;
    let state = JSON.parse(JSON.stringify(this.state)) as MenuItemState;
    let isVeg = menuItem.eatingHabit == EatingHabitType.Veg;


    return (
      <>
        <div className="card menu-item-container mt-2">
          {this.getEatingHabitIcon()}
          <img src={menuItem.imageUrl} />
          {(menuItem.subCategory == SubCategory.Pizza) && <button className="btn btn-sm btn-warning" onClick={this.toggleShow} >Click to Customise</button>}

          <div className="card-body">
            <h5 className={"title text-center" + this.getEatingHabitClass()}>{menuItem.name}</h5>
            <p className="description small text-secondary"> {menuItem.description} </p>
            <hr />
            {
              (menuItem.subCategory == SubCategory.Pizza) &&
              <>
                <div className="row g-1">
                  <div className="col-md">
                    <div className="form-floating">
                      <select className="form-select form-select-sm" value={state.base} onChange={this.baseChangedEvent} id={menuItem.name + "base"}>
                        {Object.keys(pizzaAddOns.Base).map((v, i) => <option key={menuItem.name + "base" + v} value={v}>{v}</option>)}
                      </select>
                      <label htmlFor={menuItem.name + "base"}>Base</label>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <select className="form-select form-select-sm" value={state.cheese} onChange={this.cheeseChangedEvent} id={menuItem.name + "cheese"}>
                        {Object.keys(pizzaAddOns.Cheese).map((v, i) => <option key={menuItem.name + "cheese" + v} value={v}>{v}</option>)}
                      </select>
                      <label htmlFor={menuItem.name + "cheese"}>Cheese</label>
                    </div>
                  </div>
                </div>
              </>
            }

            {/* {(menuItem.subCategory != SubCategory.Pizza) && <div className="pt-4 pb-4"></div>} */}

            <div className="row g-1">
              {
                (menuItem.subCategory == SubCategory.Burger) &&
                <div className="col-md">
                  <div className="form-floating">
                    <select className="form-select form-select-sm" value={state.cheeseSliceCount} onChange={this.cheeseSliceChangedEvent} id={menuItem.name + "cheese-slice"}>
                      {[...Array(5)].map((v, i) => <option key={menuItem.name + "cheese-slice" + i} value={i}>{i}</option>)}
                    </select>
                    <label htmlFor={menuItem.name + "cheese-slice"}>Cheese Slice</label>
                  </div>
                </div>
              }
              {
                (menuItem.subCategory != SubCategory.Burger) &&
                <div className="col-md">
                  <div className="form-floating">
                    <select className="form-select form-select-sm" value={state.size} onChange={this.sizeChangedEvent} id={menuItem.name + "size"}>
                      {Object.keys(menuItem.prices).map((v, i) => <option key={menuItem.name + "size" + v} value={v}>{v}</option>)}
                    </select>
                    <label htmlFor={menuItem.name + "size"}>Size</label>
                  </div>
                </div>
              }
              <div className="col-md">
                <div className="form-floating">
                  <select className="form-select form-select-sm" value={state.quantity} onChange={this.quantityChangedEvent} id={menuItem.name + "quantity"}>
                    {[...Array(101)].map((v, i) => <option key={menuItem.name + "quantity" + i} value={i}>{i}</option>)}
                  </select>
                  <label htmlFor={menuItem.name + "quantity"}>Quantity</label>
                </div>
              </div>
            </div>
            <div className="row">
              <h5 className="col text-success">₹{state.price}</h5>
              <button className="btn btn-outline-success btn-sm col" onClick={this.addToCart}> Add To Cart</button>
            </div>
          </div>
        </div>
        <AddOns menuItem={menuItem} menuItemState={state} addToCart={this.addToCart} toggleShow={this.toggleShow} sizeChanged={this.sizeChanged} quantityChangedEvent={this.quantityChangedEvent} baseChanged={this.baseChanged} cheeseChanged={this.cheeseChanged} toppingsChanged={this.toppingsChanged} sauceChanged={this.sauceChanged} />
      </>
    )
  }
}