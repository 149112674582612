export interface MenuItemInfo {
  name: string;
  prices: { [key: string]: number };
  description: string;
  category: Category;
  subCategory: SubCategory;
  imageUrl: string;
  eatingHabit: EatingHabitType
}

export interface OrderInfo {
  uniqueId: number
  menuItem: MenuItemInfo;
  base: string,
  cheese: string,
  quantity: number;
  size: string,
  price: number,
  toppings: string[],
  sauce: string,
  cheeseSliceCount: number
}

export enum Category {
  Italian = "Italian",
  Chinese = "Chinese",
  Tandoor = "Tandoor",
  ChholeBhature = "Chhole Bhature"
}

export enum SubCategory {
  Pizza = "Pizza",
  Burger = "Burger",
  Pasta = "Pasta",
  Noodles = "Noodles",
  Chillies = "Chillies",
  FriedRice = "Fried Rice",
  Manchurian = "Manchurian",
  Soup = "Soup",
  Roll = "Roll",
  Momos = "Momos",
  Tandoor = "Tandoor",
  ChholeBhature = "Chhole Bhature"
}


export enum EatingHabitType {
  Veg = 0,
  Egg = 1,
  NonVeg = 2
}

export enum PizzaSize {
  Small = "Small",
  Medium = "Medium",
  Large = "Large"
}

export enum ServeSize {
  Half = "Half",
  Full = "Full"
}
