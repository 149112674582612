import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";

import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';

import { menuItems, pizzaAddOns } from '../../Models/Data';

import { EatingHabitType, MenuItemInfo } from "../../Models/Menu";
import { MenuItemState } from "../MenuItem/MenuItem";
import "./AddOns.scss";
import ReactDOM from "react-dom";

export interface MenuItemProps {
    menuItem: MenuItemInfo;
    menuItemState: MenuItemState;
    addToCart: () => void,
    toggleShow: () => void;
    sizeChanged: (e: any) => void;
    quantityChangedEvent: (e: any) => void;
    baseChanged: (e: any) => void;
    cheeseChanged: (e: any) => void;
    toppingsChanged: (e: any) => void;
    sauceChanged: (e: any) => void;
}

export default class AddOns extends Component<MenuItemProps>{

    constructor(props: MenuItemProps) {
        super(props);
    }

    public addOnSection = (title: string, dataObj: any, currentValue: string[], click: (e: any) => void, skipValue: string = "", useStateSize: boolean = true, isFirst: boolean = false, isLast: boolean = false) => {
        return <div className={"row g-1 justify-content-center" + (isFirst ? " mt-2" : "") + (isLast ? " pb-3" : " mb-3")}>
            <h5 className="text-center">{title}</h5>
            {Object.keys(dataObj).map((key, i) => {
                if (key == skipValue) {
                    return;
                }
                return this.addOnItem(title, currentValue.includes(key), key, useStateSize ? dataObj[key][this.props.menuItemState.size] : dataObj[key], click);
            })}
            {!isLast && <div className="separator"></div>}
        </div>
    }

    public addOnItem = (title: string, isCurrentValue: boolean, key: string, price: string, click: (e: any) => void, customClasses: string = "col") => {
        return <div className={customClasses} key={title + key}>
            <div className={"add-ons mx-auto" + (isCurrentValue ? " selected" : "")} onClick={(e) => { e.stopPropagation(); click(key); }}>
                <div className="add-ons-title"> {key}</div>
                <div className="row">
                    <div className="col"><span className={"badge " + (isCurrentValue ? "bg-success" : "bg-secondary")}>₹{price}</span></div>
                    {isCurrentValue && <div className="col text-success text-end fs-5"><FontAwesomeIcon icon="check-circle" /></div>}
                </div>
            </div>
        </div>
    }

    private getEatingHabitIcon = () => {
        switch (this.props.menuItem.eatingHabit) {
            case EatingHabitType.Veg:
                return <span className={"position-absolute top-0 start-0 p-1 pt-0 pb-0 bg-light fw-bolder border border-3 text-success border-success"}>
                    <FontAwesomeIcon icon="circle" />
                </span>
            case EatingHabitType.Egg:
                return <span className={"position-absolute top-0 start-0 p-1 pt-0 pb-0 bg-light fw-bolder fs-5 text-warning"}>
                    <FontAwesomeIcon icon="egg" />
                </span>
            default:
                return <span className={"position-absolute top-0 start-0 p-1 pt-0 pb-0 bg-light fw-bolder border border-3 text-danger border-danger"}>
                    <FontAwesomeIcon icon="circle" />
                </span>
        }
    }

    private getEatingHabitClass = () => {
        switch (this.props.menuItem.eatingHabit) {
            case EatingHabitType.Veg:
                return " text-success";
            case EatingHabitType.Egg:
                return " text-warning";
            default:
                return " text-danger";
        }
    }

    render() {
        const menuItem = this.props.menuItem;
        const state = this.props.menuItemState;

        return ReactDOM.createPortal(
        <MDBModal className="add-ons-popup" staticBackdrop tabIndex='-1' show={!!state.pizzaAddOnsModal} onClick={(e: any) => e.stopPropagation()}>
            <MDBModalDialog centered size="fullscreen">
                <MDBModalContent>
                    <MDBModalHeader className="p-0 bg-light bg-gradient">
                        {this.getEatingHabitIcon()}
                        <img className="item-image-in-popup mx-auto d-block" src={menuItem.imageUrl} />
                    </MDBModalHeader>
                    <MDBModalBody className="p-0">
                        <div className="p-3">
                            <div className={"fs-6" + this.getEatingHabitClass()}>{menuItem.name}</div>
                            <small>{menuItem.description}</small>
                        </div>
                        <div className="container-fluid bg-light">
                            {this.addOnSection("Select Size", menuItem.prices, [state.size], this.props.sizeChanged, "", false, true)}

                            <div className="row g-1 justify-content-center mt-0 mb-0">
                                <div className="col-4 mt-0 border-end"><h5 className="text-center mt-0 mb-0">Cheese</h5></div>
                                <div className="col-8 mt-0"><h5 className="text-center mt-0 mb-0">Select Base</h5></div>
                            </div>
                            <div className="row g-1 justify-content-center mb-3">
                                {Object.keys(pizzaAddOns.Cheese).map((cheese, i) => {
                                    if (cheese == "Normal") {
                                        return;
                                    }
                                    return this.addOnItem("Select Cheese", cheese == state.cheese, cheese, (pizzaAddOns.Cheese as any)[cheese][state.size], this.props.cheeseChanged, "col-4 border-end");
                                })}
                                {Object.keys(pizzaAddOns.Base).map((base, i) => {
                                    if (base == "Normal") {
                                        return;
                                    }
                                    return this.addOnItem("Select Base", base == state.base, base, (pizzaAddOns.Base as any)[base][state.size], this.props.baseChanged, "col-4");
                                })}
                                <div className="separator"></div>
                            </div>
                            {this.addOnSection("Select Toppings", pizzaAddOns.Topping, state.toppings, this.props.toppingsChanged, "None")}
                            {this.addOnSection("Select Sauce", pizzaAddOns.Sauce, [state.sauce], this.props.sauceChanged, "", true, false, true)}
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <div className="container-fluid" onClick={e => e.stopPropagation()}>
                            <div className="row">
                                <div className="col-4">
                                    <div className="input-group" >
                                        <label className="input-group-text" htmlFor={menuItem.name + "quantity"}>Quantity</label>
                                        <select className="form-select" id={menuItem.name + "quantity"} value={state.quantity} onChange={this.props.quantityChangedEvent}>
                                            {[...Array(101)].map((v, i) => <option key={menuItem.name + "ad-quantity" + i} value={i}>{i}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-2 text-success fs-5 fw-bolder">₹{state.price}</div>
                                <div className="col-6 text-end">
                                    <MDBBtn color='secondary' onClick={this.props.toggleShow} className="me-2">Close</MDBBtn>
                                    <MDBBtn color="success" onClick={this.props.addToCart}>Add To Cart</MDBBtn>
                                </div>
                            </div>
                        </div>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>, document.body);
    }
}