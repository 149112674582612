import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import React from "react";

import OrderList from "../OrderList/OrderList";
import { Category, MenuItemInfo, OrderInfo, SubCategory } from "../../Models/Menu";

import "./Order.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface OrderState {
    filteredMenuItems: MenuItemInfo[];
    segment: string;
    orders: OrderInfo[];
}

interface OrderProps {
    menuItems: MenuItemInfo[]
}

export default class Order extends React.Component<OrderProps, OrderState> {

    constructor(props: OrderProps) {
        super(props);
        this.state = { segment: "All", filteredMenuItems: this.props.menuItems, orders: [] as OrderInfo[] };
    }

    public componentWillReceiveProps = (props: OrderProps) => {
        this.filterItems(this.state.segment, props.menuItems);
        // this.setState({ filteredMenuItems: props.menuItems });
        // this.setState({ filteredMenuItems: props.menuItems });
    }

    public onSegmentChange = (e: CustomEvent) => {
        let segment = e.detail.value;
        this.filterItems(segment, this.props.menuItems);

    }

    private filterItems = (segment: string, menuItems: MenuItemInfo[]) => {
        let filteredMenuItems: MenuItemInfo[] = [];

        switch (segment) {
            case "All":
                filteredMenuItems = menuItems;
                break;
            case SubCategory.Pizza:
                filteredMenuItems = menuItems.filter(mi => mi.subCategory == SubCategory.Pizza);
                break;
            case Category.Chinese:
                filteredMenuItems = menuItems.filter(mi => mi.category == Category.Chinese);
                break;
            case SubCategory.Burger:
                filteredMenuItems = menuItems.filter(mi => mi.subCategory == SubCategory.Burger);
                break;
            default:
                filteredMenuItems = menuItems;
                break;
        }

        this.setState({ segment: segment, filteredMenuItems: filteredMenuItems })
    }

    public changeQuantity = (info: OrderInfo, change: number) => {
        let ordersInfo = this.state.orders;
        let index = ordersInfo.findIndex(oi => oi.uniqueId == info.uniqueId);
        if (index != -1) {
            const qty = ordersInfo[index].quantity;
            const price = ordersInfo[index].price;

            ordersInfo[index].quantity += change;
            ordersInfo[index].price += (price / qty) * change;

            if (ordersInfo[index].quantity == 0) {
                ordersInfo.splice(index, 1);
            }
        }
        this.setState({ orders: ordersInfo });
    }

    public addOrderInfo = (info: OrderInfo) => {
        let ordersInfo = this.state.orders;

        const index = ordersInfo.findIndex(oi => {
            return oi.base == info.base
                && oi.cheese == info.cheese
                && oi.cheeseSliceCount == info.cheeseSliceCount
                // && oi.price == info.price            
                && oi.sauce == info.sauce
                && oi.size == info.size
                && oi.toppings.every(t => info.toppings.indexOf(t))
                && oi.menuItem.category == info.menuItem.category
                && oi.menuItem.subCategory == info.menuItem.subCategory
                && oi.menuItem.name == info.menuItem.name
        });

        if (index != -1) {
            ordersInfo[index].quantity += info.quantity;
            ordersInfo[index].price += info.price;
        }
        else {
            info.uniqueId = new Date().getTime();
            ordersInfo.push(info);
        }
        this.setState({ orders: ordersInfo });
    }

    render() {
        const filteredMenuItems = this.state.filteredMenuItems;
        const segment = this.state.segment;
        let ordersInfo = this.state.orders;
        let total = 0;
        ordersInfo.forEach(oi => total += oi.price);

        return (
            <div className="container-fluid fixed-height">
                <div className="row g-2" >
                    <div className="col-8 col-md-9 fixed-height bg-menu ps-5 pe-5">
                        <IonSegment color="danger" className="col-12 bg-menu" value={segment} onIonChange={this.onSegmentChange}>
                            <IonSegmentButton value="All">
                                <IonLabel>All</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="Pizza">
                                <IonLabel>Pizza</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="Chinese">
                                <IonLabel>Chinese</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="Tandoor">
                                <IonLabel>Tandoor</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="Burger">
                                <IonLabel>Burger</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                        <OrderList menuItems={filteredMenuItems} addOrderInfo={this.addOrderInfo} />
                    </div>
                    <div className="col-4 col-md-3 fix-sidebar">
                        <h2 className="text-center bg-danger bg-gradient text-light mt-1 p-2">Cart</h2>
                        <div className="cart-item-container">
                            {ordersInfo.map((oi, i) => {
                                let info: string = (oi.menuItem.subCategory != SubCategory.Burger ? oi.size : "");
                                info += (oi.cheeseSliceCount && oi.cheeseSliceCount > 0 ? (info.endsWith(",") ? "" : ", ") + oi.cheeseSliceCount : "")
                                info += (oi.base && oi.base != "Normal" && oi.base.length > 0 ? (info.endsWith(",") ? "" : ", ") + oi.base : "")
                                info += (oi.cheese && oi.cheese != "Normal" && oi.cheese.length > 0 ? (info.endsWith(",") ? "" : ", ") + oi.cheese : "")
                                info += (oi.sauce && oi.sauce.length > 0 ? (info.endsWith(",") ? "" : ", ") + oi.sauce : "")
                                info += (oi.toppings && oi.toppings.length > 0 ? (info.endsWith(",") ? "" : ", ") + oi.toppings.filter(t => t != "None").join(", ") : "")

                                return <div key={"cart" + oi.menuItem.name + i} className="ms-2 me-2 pb-2 cart-item-border mt-1 bg-light bg-gradient">
                                    <div className="row g-0">
                                        <div className="col-4">
                                            <img src={oi.menuItem.imageUrl} className="card-img-top" alt="card image" />
                                        </div>
                                        <div className="col-8">
                                            <div className="ps-1">
                                                <div className="m-0"> {oi.menuItem.name}</div>
                                                <div className="addons-list">{info.trim()}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col text-start">
                                            {/*  */}
                                            <div className="btn-group btn-group-sm btn-cart-quantity" role="group">
                                                <button className="btn btn-warning" onClick={e => this.changeQuantity(oi, -1)}>
                                                    {(oi.quantity > 1) && < FontAwesomeIcon icon="minus" />}
                                                    {(oi.quantity == 1) && < FontAwesomeIcon icon="trash" />}
                                                </button>
                                                <span className="ps-2 pe-2 d-inline-block"><strong>{oi.quantity}</strong></span>
                                                <button className="btn btn-warning" onClick={e => this.changeQuantity(oi, 1)}> <FontAwesomeIcon icon="plus" /></button>
                                            </div>
                                        </div>
                                        <div className="col text-end"><span className="text-success fw-bold">₹{oi.price}</span></div>
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className="position-absolute bottom-0 start-0 end-0">
                            <div className="container-fluid bg-light">
                                <div className="row p-2 fs-5">
                                    <div className="col text-start fw-bold">Total</div>
                                    <div className="col text-end text-success">₹{total}</div>
                                </div>
                                <div className="row">
                                    <div className="col d-grid mb-3">
                                        <a href="tel:8826737766" className="fs-6 text-dark" >
                                            <button className="btn btn-warning fw-bolder w-100">
                                                Please call for order<br /><FontAwesomeIcon icon="phone-alt" /> 8826737766
                                        </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}