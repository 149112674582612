import { Category, EatingHabitType, MenuItemInfo, PizzaSize, SubCategory } from "./Menu";

export const prices = {
  "Margherita Pizza": { Small: 50, Medium: 120, Large: 210 },
  "Single Topping Pizza": { Small: 60, Medium: 130, Large: 230 },
  "Double Topping Pizza": { Small: 70, Medium: 140, Large: 250 },
  "Veg Chowmein": { Half: 50, Full: 80 },
  "Veg Momos": { Full: 50 },
  "Veg Burger": { Full: 50 },
  "Chicken Burger": { Full: 80 },
  
};

export const menuItems: MenuItemInfo[] = [
  <MenuItemInfo>{
    name: "Margherita Pizza",
    description: "With a deliciously tangy liquid cheese and everyone's faviouprice mozzarella cheese.",
    category: Category.Italian,
    subCategory: SubCategory.Pizza,
    imageUrl: "/assets/images/menu-items/pizza.jpg",
    eatingHabit: EatingHabitType.Veg
  },
  <MenuItemInfo>{
    name: "Single Topping Pizza",
    description: "Delicious pizza with liquid cheese and mozzarella.",
    category: Category.Italian,
    subCategory: SubCategory.Pizza,
    imageUrl: "/assets/images/menu-items/pizza.jpg",
    eatingHabit: EatingHabitType.Veg
  },
  <MenuItemInfo>{
    name: "Double Topping Pizza",
    description: "Delicious pizza with liquid cheese and mozzarella.",
    category: Category.Italian,
    subCategory: SubCategory.Pizza,
    imageUrl: "/assets/images/menu-items/pizza.jpg",
    eatingHabit: EatingHabitType.Veg
  },
  <MenuItemInfo>{
    name: "Double Topping Pizza",
    description: "Delicious pizza with liquid cheese and mozzarella.",
    category: Category.Italian,
    subCategory: SubCategory.Pizza,
    imageUrl: "/assets/images/menu-items/pizza.jpg",
    eatingHabit: EatingHabitType.Veg
  },
  <MenuItemInfo>{
    name: "Veg Chowmein",
    description: "Delicious pizza with liquid cheese and mozzarella.",
    category: Category.Chinese,
    subCategory: SubCategory.Noodles,
    imageUrl: "/assets/images/menu-items/pizza.jpg",
    eatingHabit: EatingHabitType.Veg
  },
  <MenuItemInfo>{
    name: "Veg Momos",
    description: "Delicious pizza with liquid cheese and mozzarella.",
    category: Category.Chinese,
    subCategory: SubCategory.Momos,
    imageUrl: "/assets/images/menu-items/pizza.jpg",
    eatingHabit: EatingHabitType.Veg
  },
  <MenuItemInfo>{
    name: "Veg Burger",
    description: "Delicious pizza with liquid cheese and mozzarella.",
    category: Category.Italian,
    subCategory: SubCategory.Burger,
    imageUrl: "/assets/images/menu-items/pizza.jpg",
    eatingHabit: EatingHabitType.Veg
  },
  <MenuItemInfo>{
    name: "Chicken Burger",
    description: "Delicious pizza with liquid cheese and mozzarella.",
    category: Category.Italian,
    subCategory: SubCategory.Burger,
    imageUrl: "/assets/images/menu-items/pizza.jpg",
    eatingHabit: EatingHabitType.NonVeg
  }
].map((mi, i) => {
  mi.prices = (<any>prices)[mi.name];
  return mi;
});

export const burgerAddOns = {
  CheeseSlice: 15
}

export const pizzaAddOns = {
  Base: {
    "Normal": { Small: 0, Medium: 0, Large: 0 },
    "Thin Crust": { Small: 10, Medium: 20, Large: 30 },
    "Cheese Burst": { Small: 40, Medium: 80, Large: 120 },
  },
  Cheese: {
    "Normal": { Small: 0, Medium: 0, Large: 0 },
    "Extra Cheese": { Small: 30, Medium: 60, Large: 90 },
  },
  Topping: {
    "None": { Small: 0, Medium: 0, Large: 0 },
    "Onion": { Small: 15, Medium: 30, Large: 45 },
    "Tomato": { Small: 15, Medium: 30, Large: 45 },
    "Corn": { Small: 15, Medium: 30, Large: 45 },
    "Capsicum": { Small: 20, Medium: 40, Large: 60 },
    "Mushroom": { Small: 20, Medium: 40, Large: 60 },
    "Jalapeno": { Small: 20, Medium: 40, Large: 60 },
    "Black Olive": { Small: 20, Medium: 40, Large: 60 },
    "Red Pepperika": { Small: 20, Medium: 40, Large: 60 },
    "Green Pepperika": { Small: 20, Medium: 40, Large: 60 },
    "Chaap": { Small: 25, Medium: 50, Large: 70 },
    "Paneer": { Small: 30, Medium: 60, Large: 90 },
    "Egg": { Small: 20, Medium: 60, Large: 60 },
    "Chicken": { Small: 30, Medium: 60, Large: 90 },
  },
  Sauce: {
    "Tomato": { Small: 0, Medium: 0, Large: 0 },
    "Peri Peri": { Small: 10, Medium: 20, Large: 30 },
    "Kadai": { Small: 10, Medium: 20, Large: 30 },
    "Makhani": { Small: 10, Medium: 20, Large: 30 },
    "Achari": { Small: 10, Medium: 20, Large: 30 },
  }
};

