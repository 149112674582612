import { Component } from "react";
import { IonButton } from "@ionic/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from "react-router";

import "./Header.scss";

export interface EatingHabitProps {
    eatingHabit?: number;
    setEatingHabit?: (e: any) => void;
}

class Header extends Component<EatingHabitProps & RouteComponentProps>{
    constructor(props: EatingHabitProps & RouteComponentProps) {
        super(props);
    }


    render() {
        let eatingHabit = this.props.eatingHabit;
        const { location } = this.props;
        return <>
            <nav className="navbar page-header navbar-expand-lg fixed-top navbar-dark bg-dark">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#"><img className="logo-image" src="/assets/images/logo.png" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        {
                            location.pathname.toLowerCase() == "/menu" &&
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item"></li>
                            </ul>
                        }
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <IonButton color="warning" routerLink="/">Order Now</IonButton>
                            </li>
                            <li className="nav-item">
                                <IonButton color="warning" routerLink="/Menu">Menu</IonButton>
                            </li>
                        </ul>
                        {
                            location.pathname.toLowerCase() != "/menu" && <div className="me-auto">
                                <div className="row small fw-bolder">
                                    <div className="col">
                                        <span className="text-success float-end bg-light ps-1 pe-1 border border-2 border-success"><FontAwesomeIcon icon="circle" /></span>
                                    </div>
                                    <div className="col  text-end">
                                        <span className="text-warning"><FontAwesomeIcon className="fs-4" icon="egg" /></span>
                                    </div>
                                    <div className="col">
                                        <span className="text-danger float-end bg-light ps-1 pe-1 border border-2 border-danger"><FontAwesomeIcon icon="circle" /></span>
                                    </div>
                                </div>
                                <div className="row ps-3 pe-2">
                                    <input type="range" className="form-range col" value={eatingHabit} onChange={this.props.setEatingHabit} min="0" max="2" id="customRange2"></input>
                                </div>
                            </div>
                        }
                        {
                            // location.pathname.toLowerCase() != "/menu" && <div className="d-flex me-auto">
                            //     <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                            //     <button className="btn btn-success" type="submit">Search</button>
                            // </div>
                        }
                        <div className="text-right">
                            <strong className="contact-number">
                                <a href="tel:8826737766" className="fs-6 text-warning" > <FontAwesomeIcon icon="phone-alt" /> 8826737766</a>
                                {/* <span className="fs-6 text-warning"> | </span> */}
                                <br />
                                <a href="tel:8826737766" className="fs-6 text-warning" ><FontAwesomeIcon icon="phone-alt" /> 8826747766</a>
                            </strong>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    }
};

export default withRouter(Header);