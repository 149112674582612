import React from "react";
import { MenuItemInfo, OrderInfo } from "../../Models/Menu";
import MenuItem from "../MenuItem/MenuItem";

import "./MenuItemList.scss";

const MenuItemList: React.FC<{ menuItems: MenuItemInfo[], addOrderInfo: (orderInfo: OrderInfo) => void }> = ({ children, menuItems, addOrderInfo }) => {
    const colCount = 3;
    const cols = [...Array(colCount)];
    const rows = [...Array(Math.ceil(menuItems.length / colCount))];

    return (
        <>
            {rows.map((r, i) =>
                <div className="row g-2" key={"row " + i}>
                    {cols.map((x, j) => {
                        if (menuItems.length > (i * 3 + j)) {
                            return (
                                <div className="col-md" key={menuItems[i * 3 + j].name + (i + "," + j)}>
                                    <MenuItem menuItem={menuItems[i * 3 + j]} addOrderInfo={addOrderInfo} />
                                </div>
                            );
                        }
                        else {
                            return (
                                <div className="col-md" key={"row " + i + "col" + j}></div>
                            );
                        }
                    })}
                </div>
            )}
        </>
    );
};

export default MenuItemList;

